import getServerUrl from './getServerUrl';
import getYahooJapanRedirectUri from '../functions/getYahooJapanRedirectUri';

// authType: "logon", "login", "logonFromGuest" or "add"
export default async function handleClickYahooJapan(authType, setProcessing = f => f) {
    setProcessing(true);
    try {
        const res = await fetch(getServerUrl() + "Auth/yahoojapan/get_state", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            }
        });
        const data = await res.json();
        console.log(data);

        const state = data.state;
        const redirectUri = getYahooJapanRedirectUri();
        const url = "https://auth.login.yahoo.co.jp/yconnect/v2/authorization?response_type=code&client_id=dj00aiZpPWdyQTVyOW5jUXc4aCZzPWNvbnN1bWVyc2VjcmV0Jng9MWY-&scope=profile%20openid&redirect_uri=" + redirectUri + "&state=" + state

        localStorage.setItem("authType", authType);

        window.location.href = url;
    }
    catch (e) {
        console.error(e);
        setProcessing(false);
    }
}

