import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Form, Button, Stack, ListGroup, Pagination } from 'react-bootstrap';
import Header from './Header';
import getServerUrl from '../functions/getServerUrl';
import getCommentString from '../functions/getCommentString';
import getReadingRangeString from '../functions/getReadingRangeString';
import getReadingRangeUrl from '../functions/getReadingRangeUrl';

export default function Record() {
  const perPage = 100;

  const initialState = {
    page: 0,
    filter: "none"
  };
  const [state, setState] = useState(initialState);

  const [searchText, setSearchText] = useState("");

  const [pageNumber, setPageNumber] = useState(0);
  const [count, setCount] = useState(0);
  const [readingRecords, setReadingRecords] = useState([]);

  const [startNumber, setStartNumber] = useState(0);
  const [endNumber, setEndNumber] = useState(0);

  const userId = localStorage.getItem("userId");
  const sessionId = localStorage.getItem("sessionId")

  const jsonBooks = localStorage.getItem("books");
  const books = JSON.parse(jsonBooks);

  useEffect(() => {
    init();
  }, []);

  useEffect(() => {
    init();
  }, [state])

  const init = async () => {
    await getRecords();
  }

  const getRecords = async () => {
    const getAllRecords = async (userId, sessionId, offset, limit) => {
      const res = await fetch(getServerUrl() + "ReadingRecords/range/all?userId=" + userId + "&sessionId=" + sessionId + "&offset=" + offset + "&limit=" + limit);
      const data = await res.json();
      return data;
    }
    const getRecordsOfSearch = async (userId, sessionId, searchText, offset, limit) => {
      const res = await fetch(getServerUrl() + "ReadingRecords/search?userId=" + userId + "&sessionId=" + sessionId + "&text=" + searchText + "&offset=" + offset + "&limit=" + limit);
      const data = await res.json();
      return data;
    }

    const offset = (state.page) * perPage;
    let newReadingRecords = [];
    let newCount = -1;
    let data;
    switch (state.filter) {
      case "search":
        data = await getRecordsOfSearch(userId, sessionId, searchText, offset, perPage);
        newReadingRecords = data.readingRecords;
        newCount = data.totalCount;
        break;
      default:
        data = await getAllRecords(userId, sessionId, offset, perPage);
        newReadingRecords = data.readingRecords;
        newCount = data.totalCount;
        break;
    }

    const newStartNumber = newReadingRecords.length !== 0 ? offset + 1 : 0;
    const newEndNumber = newReadingRecords.length !== 0 ? newStartNumber + newReadingRecords.length - 1 : 0;

    setReadingRecords(newReadingRecords);
    setStartNumber(newStartNumber);
    setEndNumber(newEndNumber);

    const newPageNumber = newCount === 0 ? 0 : Math.floor((newCount - 1) / perPage);

    setCount(newCount);
    setPageNumber(newPageNumber);
  }

  const handleClickWithoutFilter = async () => {
    const newState = {
      filter: "none",
      page: 0
    };
    setState({ ...state, ...newState });
  }

  const handleKeyDownSearchText = (event) => {
    if (event.nativeEvent.isComposing || event.key !== 'Enter') return;
    handleClickFilterBySearch();
  }
  const handleChangeSearchText = (event) => {
    setSearchText(event.target.value);
  }
  const handleClickFilterBySearch = async () => {
    const newState = {
      filter: "search",
      page: 0
    };
    setState({ ...state, ...newState });
  }

  const handleClickFirstPage = async () => {
    const newState = {
      page: 0
    };
    setState({ ...state, ...newState });
  }

  const handleClickPrevPage = async () => {
    const newPage = state.page > 0 ? state.page - 1 : 0;
    const newState = {
      page: newPage
    };
    setState({ ...state, ...newState });
  }

  const handleClickNextPage = async () => {
    const newPage = state.page < pageNumber ? state.page + 1 : pageNumber;
    const newState = {
      page: newPage
    };
    setState({ ...state, ...newState });
  }

  const handleClickLastPage = async () => {
    const newState = {
      page: pageNumber
    };
    setState({ ...state, ...newState });
  }

  return (
    <>
      <Header />

      <Container className="mt-4 mb-3">
        <Row>
          <Col xxl={1} xl={6} lg={6} md={6} sm={4} xs={12} className="mb-3">
            <Button variant="success" onClick={handleClickWithoutFilter} >全記録</Button>
          </Col>
          <Col xxl={3} xl={6} lg={6} md={6} sm={8} xs={12} className="mb-3">
            <Stack direction="horizontal">
              <Form.Group className="gap-book-chapter">
                <Form.Control type="text" value={searchText} onChange={(e) => handleChangeSearchText(e)} onKeyDown={(e) => handleKeyDownSearchText(e)} />
              </Form.Group>
              <Button variant="success" className="gap-left" onClick={handleClickFilterBySearch} >検索</Button>
            </Stack>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col xs="auto">
            {startNumber} - {endNumber} 件 （全 {count} 件）
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col xs="auto">
            <Pagination>
              <Pagination.First onClick={handleClickFirstPage} />
              <Pagination.Prev onClick={handleClickPrevPage} />
              <Pagination.Next onClick={handleClickNextPage} />
              <Pagination.Last onClick={handleClickLastPage} />
            </Pagination>
          </Col>
        </Row>
        <Row>
          <Col>
            <ListGroup>
              {readingRecords.map((readingRecord, index) => (
                <ListGroup.Item key={index}>
                  {readingRecord.date.toString().substring(0, 10).replaceAll('-', '/')}
                  &nbsp; &nbsp;
                  <a href={getReadingRangeUrl({
                    book: books[readingRecord.readingRange.bookId],
                    chapter: readingRecord.readingRange.chapter,
                    startVerse: readingRecord.readingRange.startVerse,
                    endVerse: readingRecord.readingRange.endVerse
                  })} target="_blank" rel="noopener noreferrer">
                    {getReadingRangeString({
                      book: books[readingRecord.readingRange.bookId],
                      chapter: readingRecord.readingRange.chapter,
                      startVerse: readingRecord.readingRange.startVerse,
                      endVerse: readingRecord.readingRange.endVerse
                    })}
                  </a>
                  &nbsp; &nbsp;
                  {getCommentString(readingRecord.comment)}
                </ListGroup.Item>
              ))}
            </ListGroup>
          </Col>
        </Row>
      </Container>
    </>
  )
}